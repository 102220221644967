import { useEffect, useRef } from "react";
import lockIcon from "../icons/lock.svg";
import { useTranslation } from "react-i18next";

export default function UnderConstruction({ parentDimensions, parent }) {
    const elementRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            const parentElement = parent.current;

            const element = elementRef.current;
            const containerWidth = parentElement.offsetWidth;

            element.style.width = `${containerWidth * 1.80}px`
            element.style.right = `-${containerWidth / 3}px`
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, [elementRef]);

    const rotatedDivStyles = {
        position: 'absolute',
        top: '100px',
        transform: 'rotate(-40deg)',
        backgroundColor: 'rgba(230, 126, 34,0.65)',
        padding: '10px 0',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(10px)',
        gap: '20px'
    };

    return(
        <div style={rotatedDivStyles} ref={elementRef}>
            <img src={lockIcon} style={{height: '27px'}} alt="Slēgts"/>
            <h4 style={{
                fontSize: '25px',
                fontWeight: '500',
                opacity: '0.9'
            }}>{ t('temporaryClosed') }</h4>
        </div>
    )
}