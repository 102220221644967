
import Homepage from './components/Homepage';
import RestPage from './components/RestPage';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import './css/style.css';

const App = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const langFromPath = location.pathname.split('/')[1];

        if(langFromPath === 'en' || langFromPath === 'lv'){
            i18n.changeLanguage(langFromPath);
            localStorage.setItem('language', langFromPath);
            const newPath = location.pathname.substring(langFromPath.length + 1);
            navigate(newPath)
        }
    }, []);

    return(
        <>
            <Routes>
                <Route path="/" element={ <Homepage /> } />
                <Route path="/izbraukumi" element={ <Homepage /> } />
                <Route path="/tirgus" element={ <Homepage /> } />
                <Route path="/parmums" element={ <Homepage /> } />
                <Route path="/kokteili" element={ <Homepage /> } />
                <Route path="/united" element={ <Homepage /> } />
                <Route path="/buj" element={ <Homepage /> } />
                <Route path=":rest" element={ <RestPage /> } />
            </Routes>
        </>
    )
}

export default App;