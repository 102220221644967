import React, { useState } from 'react';
import menuIcon from '../icons/menu.svg';
import Sidenav from './Sidenav';

export default function Topbar() {
    const [status, setStatus] = useState(false);
    return(
        <>
            <div className="topbar">
                <div className="topbarContent">
                    <img src={menuIcon}
                        alt="Navigācija"
                        onClick={() => setStatus(true)}
                    />
                </div>
            </div>
            <Sidenav opened={status} setStatus={setStatus} />
        </>
    )
}