import { useRef } from 'react';
import CocktailsImg from '../img/restaurants/iggirezekne/iggir_6.jpg';
import VaraklanuLogo from '../img/varaklanu.png';
import { useTranslation } from "react-i18next";

export default function Cocktails() {
    const anchorRef = useRef(null);
    const { t } = useTranslation();

    return(
        <div className="container" style={{marginTop: '170px'}} id="cocktails" ref={anchorRef}>
            <div className="homepageContent homepageContentImg" style={{alignItems: 'flex-start'}}>
                <div className="homepageText">
                    <h2>{ t('cocktails.title') }</h2>
                    <p dangerouslySetInnerHTML={{ __html: t('cocktails.description') }} />
                    <img src={VaraklanuLogo} alt="Vara Klānu logo" style={{width: '200px', marginTop: '40px'}}/>
                </div>
                <div className="homepageImg">
                    <img src={CocktailsImg} alt="IGGI kokteiļi" style={{objectPosition: 'bottom'}} />
                </div>
            </div>
        </div>
    )
}