const RestGallery = ({ rest, images }) => {
    
    const imgDiv = images.map((val, key) => {
        return <div className="imgDiv" key={key}><img src={ require("../img/restaurants/"+rest+"/"+val) } alt={rest} /></div>
    })
    
    return(
        <div className="imgGallery">
           { imgDiv }
        </div>
    )
}

export default RestGallery;