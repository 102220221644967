import { useTranslation } from "react-i18next";

const LANGUAGES = [
    { label: 'LV', labelFull: 'Latviski', code: 'lv' },
    { label: 'ENG', labelFull: 'English', code: 'en' }
];

export default function LangSelect({ dark = false, text = 'right' }) {
    const { i18n } = useTranslation();

    function setLanguage(lang) {
        i18n.changeLanguage(lang)
        localStorage.setItem('language', lang);
    }

    return(
        <div>
            <select
                value={i18n.language}
                onChange={ (lang) => setLanguage(lang.currentTarget.value) }
                className="langSelect"
                style={{ color: dark ? 'black' : '', textAlign: text }}
            >
                { LANGUAGES.map(({ code, label }) => (
                    <option key={code} value={code}>
                        {label}
                    </option>
                )) }
            </select>
        </div>
    )
}

export function LangSelectFooter() {
    const { i18n } = useTranslation();

    function setLanguage(lang) {
        i18n.changeLanguage(lang)
        localStorage.setItem('language', lang);
    }

    return(
        <div>
            <select
                value={i18n.language}
                onChange={ (lang) => setLanguage(lang.currentTarget.value) }
                className="langSelectFooter"
            >
                { LANGUAGES.map(({ code, labelFull }) => (
                    <option key={code} value={code}>
                        {labelFull}
                    </option>
                )) }
            </select>
        </div>
    )
}