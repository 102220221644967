import { useEffect, useRef } from 'react';
import UnitedImg from '../img/united/united.jpg';
import United_1 from '../img/united/united_1.jpg';
import United_2 from '../img/united/united_2.jpg';
import United_3 from '../img/united/united_3.jpg';
import United_4 from '../img/united/united_4.jpg';
import { useTranslation } from "react-i18next";


function animateScroll(element) {
    let scrollAmount = 0;
    let max = false;
  
    function scroll() {
        if(!max){
            element.scrollLeft = scrollAmount;
            scrollAmount += 3;    
        }
        if(scrollAmount > 50) {
            max = true;
            backToStart();
            return;
        }
        requestAnimationFrame(scroll);
    }

    function backToStart() {
        if(!max) return;
        element.scrollLeft = scrollAmount;
        scrollAmount -= 3;
        if(scrollAmount === 0) {
            max = false;
            return;
        }
        requestAnimationFrame(backToStart);
    }
    scroll();
    backToStart();

}

export default function United() {
    const anchorRef = useRef(null);
    const innerDivRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {

        setTimeout(() => {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                animateScroll(innerDivRef.current);
              }
            });
          });
    
          observer.observe(innerDivRef.current);
    
          return () => {
            observer.unobserve(innerDivRef.current);
          };
        }, 4000);
      }, []);

    return(
        <div className="container" style={{marginTop: '170px'}} id="united" ref={anchorRef}>
            <div className="united">
                <div className="unitedImg" ref={innerDivRef}>
                    <img src={UnitedImg} alt="IGGI United" />
                    <img src={United_1} alt="IGGI United" />
                    <img src={United_2} alt="IGGI United" />
                    <img src={United_3} alt="IGGI United" />
                    <img src={United_4} alt="IGGI United" />
                </div>
                <div className="unitedText">
                    <h2>{ t('united.title') }</h2>
                    <p dangerouslySetInnerHTML={{ __html: t('united.description') }} />
                </div>
            </div>
        </div>
    )
}