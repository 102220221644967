import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import lv from "./locales/lv.json";
import en from "./locales/en.json";

const selectedLanguage = localStorage.getItem('language') || 'lv';

export default i18n
    .use(initReactI18next)
    .init({
        lng: selectedLanguage,
        fallbackLng: "lv",
        returnObjects: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            lv: {
                translation: lv,
            },
            en: {
                translation: en,
            }
        }
});