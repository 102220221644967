import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

export const useGetTableData = () => {
    const [data, setData] = useState(null);
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`https://api.meals.lv/public/restinfo`);
                setData(data);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();

        const intervalCall = setInterval(() => {
            fetchData();
        }, 300000);
        return () => {
            clearInterval(intervalCall);
        };

    }, []);
  
    return data;
};

export function TablesStatus({ rest, data }) {
    const { t, i18n} = useTranslation();

    if(!data){
        return(
            <div className="tableStatus">
                <div className="statusContent">
                    <div className="statusText">
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        if(data !== 'error'){
            if(data[rest]){
                const restData = data[rest];
                if(restData.open.status === false){
                    const timeFromOpen = (restData.open.from !== null ? restData.open.from : false);
                    return(
                        <>
                            <div className="tableStatus" id={`statusTooltip-${rest}`}>
                                <div className="statusContent">
                                    <div className="statusText">{(timeFromOpen ? t('openFrom') + ' ' +timeFromOpen : t('closed'))}</div>
                                </div>
                            </div>
                        </>
                    )
                }else{
                    return(
                        <>
                            <div className="tableStatus" id={`statusTooltip-${rest}`}>
                                <div className={"blob " + (restData.tables.available > 0 ? "green_blob" : "red_blob")}></div>
                                <div className="statusContent">
                                    { i18n.language === 'lv' ? <div className="statusText">Pieejam{(restData.tables.available === 1 ? 's' : 'i')} <b>{restData.tables.available}</b> galdiņ{(restData.tables.available === 1 ? 'š' : 'i')}</div>
                                    : <div className="statusText">{ t('available') } <b>{restData.tables.available}</b> table{(restData.tables.available > 1 ? 's' : '')}</div>}
                                </div>
                            </div>
                        </>
                    )
                }
            }
        }
    }
}