import iggiLogo from '../img/logo/iggi.png';
import osteriaLogo from '../img/logo/osteria.svg';

import { Link } from 'react-router-dom';
import HomepageBlock from './HomepageBlock';
import AboutUs from './AboutUs';
import Footer from './Footer';
import Cocktails from './Cocktails';
import Faq from './Faq';
import Topbar from './Topbar';
import { useGetTableData } from './TableStatus';
import United from './United';
import Bus from './bus';
import Garden from './Garden';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useTranslation } from "react-i18next";

const Homepage = () => {
    const tableData = useGetTableData();
    const { t } = useTranslation();

    const location = useLocation();

    const getMetaData = () => {
      switch (location.pathname) {
        case '/izbraukumi':
            return {
                title: t('bus.title') + ' | IGGI',
                description: t('bus.metaDescription'),
            };
        case '/tirgus':
            return {
                title: 'Ābeļdārza tirgus | IGGI',
                description: 'Ābeļdārza Tirgus ir videi draudzīga un mājīga vieta, kur tirgojas zemnieki, mājražotāji un amatnieki. Kā arī notiek andeles un dažādi kultūras pasākumi, kur var iegādāties kvalitatīvus zemnieku labumus, kurus nevar atrast veikalu plauktos.',
            };
        case '/parmums':
            return {
                title: t('aboutus.title') + ' | IGGI',
                description: t('aboutus.metaDescription'),
            };
        case '/kokteili':
            return {
                title: t('cocktails.title') + ' | IGGI',
                description: t('cocktails.metaDescription'),
            };
        case '/united':
            return {
                title: t('united.title') + ' | IGGI',
                description: t('united.metaDescription'),
            };
        case '/buj':
            return {
                title: 'BUJ | IGGI',
                description: 'Biežāk uzdotie jautājumi',
            };
        default:
            return {
                title: t('metaTitle'),
                description: t('metaDescription'),
            };
      }
    };

    const metaData = getMetaData();

    useEffect(() => {
        const sectionId = location.pathname.slice(1);
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    return(
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <Topbar />
            <div className="blocks" id="home">
                <Link
                    to="/iggirezekne"
                    className="block first-block"
                    >
                    <HomepageBlock tableData={tableData} logo={iggiLogo} city="Rēzekne" phone="22 107 107" address="Atbrīvošanas aleja 95C" rest="iggirezekne"/>
                </Link>
                <Link
                    to="/osteria"
                    className="block second-block"
                    >
                    <HomepageBlock
                        tableData={tableData}
                        logo={osteriaLogo}
                        showStatus={false}
                        city="Rēzekne"
                        address="Atbrīvošanas aleja 142"
                        rest="osteria"
                    />
                </Link>
                <Link
                    to="/iggidaugavpils"
                    className="block third-block"
                    >
                    <HomepageBlock tableData={tableData} logo={iggiLogo} city="Daugavpils" phone="28 008 600" address="Rīgas iela 22" rest="iggidaugavpils" />
                </Link>
            </div>
            <section id="parmums">
                <AboutUs />
            </section>
            <section id="kokteili">
                <Cocktails />
            </section>
            <section id="izbraukumi">
                <Bus />
            </section>
            <section id="tirgus">
                <Garden />
            </section>
            <section id="united">
                <United />
            </section>
            <section id="buj">
                <Faq />
            </section>
            <Footer />
        </>
    )
}

export default Homepage;