import React from 'react';
import closeImg from '../icons/close.svg';
import LangSelect from './LangSelect';
import { useTranslation } from "react-i18next";

export default function Sidenav({ opened, setStatus }){
    const { t } = useTranslation();

    if(opened === true){
        document.body.style.overflowY = "hidden";
    }else{
        document.body.style.overflowY = "scroll";
    }
    return(
        <div className={"sidenav " + (opened === true ? "sidenavActive" : '') }>
                <div className="sidenavOverlay"
                    onClick={() => setStatus(false)}
                >
                </div>
                <div className="sidenavContent">
                    <div className="sidenavHeader">
                        <LangSelect dark text="left"/>
                        <img src={closeImg} alt="Aizvērt"
                            onClick={() => setStatus(false)}
                        />
                    </div>
                    <nav>
                        <ul onClick={() => setStatus(false)}>
                            <li>
                                <a href="/parmums" alt="Par mums">{ t('aboutus.title') }</a>
                            </li>
                            <li>
                                <a href="/izbraukumi" alt="IGGI izbraukumi">{ t('bus.title') }</a>
                            </li>
                            <li>
                                <a href="/tirgus" alt="IGGI Ābeļdārza tirgus">Ābeļdārza tirgus</a>
                            </li>
                            <li>
                                <a href="/kokteili" alt="IGGI kokteiļi">{ t('cocktails.title') }</a>
                            </li>
                            <li>
                                <a href="/united" alt="IGGI United">{ t('united.title') }</a>
                            </li>
                            <li>
                                <a href="/buj" alt="BUJ">{ t('faq') }</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
    )
}