import { useRef } from 'react';
import aboutUsImg from '../img/aboutus.jpg';
import { useTranslation } from "react-i18next";

export default function AboutUs() {
    const anchorRef = useRef(null);
    const { t } = useTranslation();

    return(
        <div className="container" id="aboutus" ref={anchorRef}>
            <div className="homepageContent homepageContentImg">
                <div className="homepageImg">
                    <img src={aboutUsImg} alt="Igors Nikitns" />
                </div>
                <div className="homepageText">
                    <h2>{ t('aboutus.title') }</h2>
                    <p dangerouslySetInnerHTML={{ __html: t('aboutus.description') }} />
                </div>
            </div>
        </div>
    )
}