import { useRef } from "react";
import { TablesStatus } from "./TableStatus";
import UnderConstruction from "./UnderConstruction";

const HomepageBlock = ({ logo, city, address, phone, rest, tableData, underConstruction = false, showStatus = true }) => {
    const blockRef = useRef();

    return(
        <div ref={blockRef}>
            <div>
                { (!underConstruction && showStatus) && <TablesStatus rest={rest} data={tableData} /> }
                <img src={ logo } className="block-logo" alt="" />
                <div className="block-text">
                    <h3>{ city }</h3>
                    <div>
                        <p>{ address }</p>
                        { phone && <p>+371 { phone }</p> }
                    </div>
                </div>
            </div>
            { underConstruction && <UnderConstruction parent={blockRef} /> }
        </div>

    )
}

export default HomepageBlock;