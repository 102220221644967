import { useRef } from 'react';
import LocationIcon from '../icons/location.svg';
import ClockIcon from '../icons/clock.svg';
import CalendarIcon from '../icons/calendar.svg';
import MailIcon from '../icons/mail.svg';
import MarketImg from '../img/market.png';

export default function Garden() {
    const anchorRef = useRef(null);

    return(
        <>
            <div className="container" style={{marginTop: '170px'}} id="garden" ref={anchorRef}>
                <div className="homepageContent homepageContentImg" style={{alignItems: 'flex-start'}}>
                    <div className="homepageText">
                        <h2>Ābeļdārza tirgus</h2>
                        <p>
                        Ābeļdārza Tirgus ir videi draudzīga un mājīga
                        vieta, kur tirgojas zemnieki, mājražotāji un
                        amatnieki. Kā arī notiek andeles un dažādi
                        kultūras pasākumi, kur var iegādāties kvalitatīvus
    zemnieku labumus, kurus nevar atrast veikalu
    plauktos.
                        </p>
                        <h4>Kas ir andele?</h4>
                        <p>Tas ir tirgus, kur iespējams gan nopirkt daudz
    un dažādas skaistas lietas par saprātīgām
    cenām, gan tirgot savas lietas, kuras vairs nav
    vajadzīgas, bet varētu noderēt un patikt kādam
    citam.</p>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '50px'}}>
                            <div style={{border: '1px solid rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', gap: '20px', borderRadius: '10px', padding: '10px 15px'}}>
                                <img src={CalendarIcon} style={{width: '30px'}} alt="Calendar icon"/>
                                <div>
                                    <h3 style={{margin: '0px', fontWeight: 600, fontSize: '18px'}}>Kad?</h3>
                                    <p style={{margin: '0px'}}>2 reizes mēnesī - sestdienās</p>
                                </div>
                            </div>
                            <div style={{border: '1px solid rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', gap: '20px', borderRadius: '10px', padding: '10px 15px'}}>
                                <img src={ClockIcon} style={{width: '30px'}} alt="Clock icon"/>
                                <div>
                                    <h3 style={{margin: '0px', fontWeight: 600, fontSize: '18px'}}>Cikos?</h3>
                                    <p style={{margin: '0px'}}>9:00 - 14:00</p>
                                </div>
                            </div>
                            <div style={{border: '1px solid rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', gap: '20px', borderRadius: '10px', padding: '10px 15px'}}>
                                <img src={LocationIcon} style={{width: '30px'}} alt="Location icon"/>
                                <div>
                                    <h3 style={{margin: '0px', fontWeight: 600, fontSize: '18px'}}>Kur?</h3>
                                    <p style={{margin: '0px'}}>“Osteria pasta & vīns” ābeļdārzā,
                                    Atbrīvošanas alejā 142, Rēzeknē</p>
                                </div>
                            </div>
                            <div>
                                <h3 style={{fontWeight: 500}}>Vēlies tirgoties?</h3>
                            </div>
                            <div style={{border: '1px solid rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', gap: '20px', borderRadius: '10px', padding: '10px 15px'}}>
                                <img src={MailIcon} style={{width: '30px'}} alt="Mail icon"/>
                                <div>
                                    <h3 style={{margin: '0px', fontWeight: 600, fontSize: '18px'}}>Sazinies ar mums</h3>
                                    <p style={{margin: '0px'}}>+371 20655740 vai siaiggi@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homepageImg">
                        <img src={MarketImg} alt="IGGI kokteiļi" style={{objectPosition: 'bottom'}} />
                    </div>
                </div>
            </div>
            </>
    )
}